import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import common_en from "./translations/en.json";
import common_es from "./translations/es.json";

const resources = {
  en: { translation: common_en },
  es: { translation: common_es },
};

const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  detection: DETECTION_OPTIONS,
  resources,
  fallbackLng: "es",
});

export default i18n;